/* Graph Side */
.Graphside {
  margin-bottom: 70px;
  @include md {
    margin-bottom: 20px;
  }
  h2 {
    font-weight: 700;
    color: $orange;
    margin-bottom: 20px;
  }
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      position: relative;
      padding-left: 18px;
      font-family: $fontFamilyPop;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        position: absolute;
        top: 8px;
        left: 0;
        width: 5px;
        height: 5px;
        background-color: $darkCharcoal;
        content: "";
        @include border-radius(50%);
      }
      ol {
        padding: 0;
        list-style: none;
        margin: 10px 0 0;
        li {
          &:before {
            background-color: $orange;
          }
        }
      }
    }
  }
  img {
    width: 100%;
    @include md {
      margin-bottom: 20px;
    }
  }
}

/* Trivia Side */
.Triviaside {
  padding-bottom: 50px;
  border-bottom: 2px dashed $philippineSilver;
  margin-bottom: 50px;
  overflow: hidden;
  @include md {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  h2 {
    color: $orange;
    margin-bottom: 25px;
    span {
      font-size: 34px;
      @include md {
        font-size: 26px;
      }
    }
    @include md {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 15px;
    }
  }
  .box {
    ul {
      padding: 0;
      list-style: none;
      margin-bottom: 0;
      margin-left: -30px;
      margin-right: -30px;
      li {
        padding-left: 30px;
        padding-right: 30px;
        margin: 10px 0;
        .number {
          width: 79px;
          float: right;
          .form-control {
            padding: 0 10px;
            height: 35px;
            @include border-radius(5px);
            background-color: $bgcolor;
            color: $gray;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            line-height: 35px;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
        span {
          position: relative;
          display: block;
          overflow: hidden;
          font-weight: 700;
          line-height: 35px;
          padding-left: 15px;
          @include md {
            font-size: 16px;
          }
          &:before {
            position: absolute;
            top: 13px;
            left: 0;
            width: 6px;
            height: 6px;
            @include border-radius(50%);
            content: "";
            display: block;
            background-color: $orange;
          }
        }
      }
    }
    .btn {
      font-size: 22px;
      font-weight: 700;
      width: 100%;
      @include lg {
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }
}

/* Quote side */
.Quoteside {
  margin-bottom: 40px;
  @include md {
    margin-bottom: 10px;
  }

  .quote-arrow {
    background: $bronze;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 4px;
    border: none;
    outline: none;
  }

  &.page {
    .box {
      margin-bottom: 30px;
      .bg {
        .author {
          h2 {
            margin-bottom: 0;
          }
          .quote-icn {
            margin-left: 10px;
            width: 42px;
            height: 47px;
            @include border-radius(0%);
            -o-object-fit: cover;
            object-fit: cover;
            margin-right: -30px;
          }
        }
      }
    }
  }
  .quote {
    padding: 0;
    border: none;

    width: 95% !important;
    margin: 2rem 1rem;

    @include md {
      width: 100% !important;
      margin: 0;
      margin-bottom: 1rem;
    }

    overflow: hidden;
    @include border-radius(4px);
    @include box-shadow(0 5px 10px rgba(0, 0, 0, 0.1));

    .quote-text {
      color: $darkbgcolor;
      padding: 1rem 1.5rem;
      min-height: 230px;
      display: grid;
      align-items: center;
      text-align: center;
      @include md {
        min-height: 300px;
      }

      span {
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
        @include md {
          font-size: 16px;
          line-height: 24px;
        }
      }

      i {
        margin: 0px 10px;
        font-size: 22px;
        color: $orange;
      }
    }

    .quote-author {
      padding: 1rem;
      text-align: center;
      background: linear-gradient(137deg, rgba(208, 124, 59, 1) 0%, rgba(76, 84, 92, 1) 100%);

      h2 {
        color: $white;
        .quote-icn {
          margin-left: 10px;
          width: 30px;
          height: 30px;
          @include border-radius(50%);
          -o-object-fit: cover;
          object-fit: cover;
          margin-right: -30px;
        }
      }
      .city {
        color: $white;
      }
    }
  }
  .btn {
    margin-top: 30px;
    padding-left: 35px;
    padding-right: 35px;
    font-size: 22px;
    font-weight: 700;
    @include md {
      padding-left: 25px;
      padding-right: 25px;
      font-size: 18px;
    }
  }
}

/* Correct Side */
.Correctside {
  padding-bottom: 60px;
  @include md {
    padding-bottom: 35px;
  }
  h2 {
    color: $orange;
    margin-bottom: 20px;
  }
  ul {
    list-style: decimal;
    margin-bottom: 0;
    li {
      font-family: $fontFamilyPop;
      margin-bottom: 15px;
    }
  }
}

/* Get Side */
.Getside {
  padding-top: 30px;
  padding-bottom: 15px;
  background-color: $gray;
  h2 {
    color: $white;
    margin-bottom: 15px;
  }
  p {
    color: $white;
    a {
      color: $white;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .form-group {
    .form-control {
      @include border-radius(30px);
      font-size: 14px;
      font-weight: 700;
      border: 0;
      color: $blackOlive;
    }
  }
}

/* Article Side */
.Articleside {
  padding-top: 50px;
  padding-bottom: 70px;
  @include lg {
    padding-top: 30px;
    padding-bottom: 10px;
  }
  h2 {
    color: $orange;
    margin-bottom: 30px;

    @include md {
      margin-bottom: 1rem;
      text-align: center;
    }
  }
  .box {
    @include md {
      margin-bottom: 30px;
    }
    .over {
      padding-bottom: 40px;
      @include box-shadow(0 5px 10px rgba(0, 0, 0, 0.1));
      background-color: $white;
      height: 100%;
      position: relative;
      .img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .text {
        padding: 20px;
        h4 {
          a {
            color: $davysGrey;
          }
          margin-bottom: 0;
        }
        .date {
          font-size: 16px;
          font-family: $fontFamilyPop;
          font-weight: 500;
          font-style: italic;
          color: $orange;
          display: block;
          margin-bottom: 10px;
        }

        .btn {
          padding-left: 30px;
          padding-right: 30px;
          position: absolute;
          bottom: 20px;
          left: 20px;
          font-size: 16px;
          font-weight: 400;
          text-transform: capitalize;
        }
      }
    }
  }
}

/* List Side */
.Listside {
  padding-top: 40px;
  padding-bottom: 70px;
  @include lg {
    padding-top: 30px;
    padding-bottom: 40px;
  }

  @include md {
    padding: 0;
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding-left: 30px;
      margin-bottom: 20px;
      background-image: url(../../src/assets/images/check-icn.png);
      background-position: 0 5px;
      background-repeat: no-repeat;
      background-size: 16px auto;
      font-size: 18px;
      @include lg {
        font-size: 16px;
      }
      @include md {
        font-size: 14px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      b {
        color: $orange;
      }
    }
  }
  img {
    width: 100%;
    @include md {
      margin-bottom: 20px;
    }
  }
}
/* Banner Side */
.Bannerside {
  .item {
    // min-height: 546px;
    .container {
      video {
        max-height: 450px;
        width: 100%;
        -o-object-fit: fill;
        object-fit: fill;
        cursor: pointer;
      }
    }
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include lg {
      padding-top: 50px;
      padding-bottom: 50px;
      min-height: inherit;
    }

    @include md {
      padding: 0;
      margin-bottom: 1rem;
    }
    h1 {
      color: $white;
      margin-bottom: 20px;
      line-height: 46px;
      @include md {
        line-height: 32px;
      }
      br {
        @include sm {
          display: none;
        }
      }
    }
    p {
      color: $white;
      margin-bottom: 30px;
    }
    .btn {
      font-weight: 700;
      max-width: 150px;
      width: 100%;
    }
  }
}
/* About Side */
.Aboutside {
  padding-bottom: 40px;
  @include lg {
    padding-bottom: 20px;
  }
  .container {
    background: $white;
    padding: 30px;
    box-shadow: 0px 0px 5px silver;
  }
  h2 {
    color: $orange;
    margin-bottom: 40px;
    @include lg {
      margin-bottom: 20px;
    }
  }
  .img {
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
  p {
    font-size: 18px;
    color: $black;
    font-family: "Poppins", sans-serif;
  }
}

/* Login Side */
.wrongDetails {
  padding: 5px;
  color: red;
  padding-left: 1rem;
}
.formSuccess {
  padding: 5px;
  color: #00ad00;
  padding-left: 1rem;
}
.Loginside {
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 1px solid $chineseSilver;
  min-height: 100vh;

  .login {
    // background: $white;
    border-radius: 10px;
  }

  h2 {
    font-family: "Poppins", sans-serif;
  }
  @include lg {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .margin-b-10 {
    margin-bottom: 10px;
  }
  .margin-b-20 {
    margin-bottom: 20px;
  }
  &.dashboard {
    border-top: 0;
    @include lg {
      padding-top: 10px;
      padding-bottom: 0px;
    }
    h6 {
      font-weight: 700;
      color: $outerSpace;
      text-align: center;
      margin-bottom: 30px;
      @include md {
        margin-bottom: 15px;
      }
    }
    .form-group {
      //margin-bottom: 45px !important;
    }
  }
  &.forgot {
    .bg {
      .right {
        .form-group {
          .btn {
            margin-top: 0;
            @include md {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  .bg {
    background-color: $white;
    @include box-shadow(0 0 15px rgba(0, 0, 0, 0.1));
    @include border-radius(15px);
    padding: 2rem;
    // overflow: hidden;
    // min-height: 70vh;
    height: auto;
    .left {
      padding: 50px 30px;
      position: relative;
      background-color: $gray;
      min-height: 600px;
      @include md {
        padding: 30px 15px 50px;
        min-height: inherit;
      }
      h3 {
        color: $white;
        font-weight: normal;
      }
      .logo {
        margin-bottom: 15px;
        @include md {
          text-align: center;
        }
      }
      h2 {
        font-size: 42px;
        font-weight: 400;
        color: $white;
        @include lg {
          font-size: 32px;
        }
        @include md {
          padding-bottom: 20px;
          text-align: center;
          br {
            display: none;
          }
        }
        @include sm {
          font-size: 24px;
        }
      }
      .nav-tabs {
        padding: 0;
        position: absolute;
        right: 0;
        bottom: 50%;
        list-style: none;
        margin: 0;
        border: 0;
        margin-bottom: -50px;
        @include md {
          margin-bottom: 0;
          bottom: 0;
          width: 100%;
          text-align: center;
        }
        .nav-item {
          margin: 0;
          @include md {
            display: inline-block;
          }
          .nav-link {
            padding: 10px 15px;
            font-size: 18px;
            font-family: $fontFamily;
            font-weight: 500;
            color: $white;
            min-width: 120px;
            text-align: center;
            border: 0;
            @include border-radius(30px 0 0 30px);
            @include md {
              @include border-radius(10px 10px 0 0);
            }
            &.active {
              font-weight: 500;
              background-color: $ghostWhite;
              color: $gray;
            }
          }
        }
      }
    }
    .right {
      padding: 50px;
      @include md {
        padding: 30px 15px 0;
      }
      h2 {
        font-weight: 900;
        color: $darkGunmetal;
        margin-bottom: 70px;
        @include md {
          margin-bottom: 25px;
        }
        span {
          font-size: 13px;
          font-weight: 400;
          color: $graniteGrey;
          margin-top: 10px;
        }
      }
      .form-group {
        margin-bottom: 25px;
        .position-relative {
          .fa {
            position: absolute;
            top: 0;
            left: 20px;
            bottom: 0;
            font-size: 14px;
            line-height: 42px;
            color: $philippineSilver;
            &.envelope {
              font-size: 12px;
            }
          }
        }
        .form-control {
          font-weight: 500;
          padding-left: 45px;
        }
        .custom-select {
          font-size: 14px;
          font-weight: 500;
          color: $darkCharcoal;
        }
        .forgot {
          margin-top: 10px;
          display: inline-block;
          font-size: 15px;
          font-weight: 500;
          color: $orange;
        }
        .back {
          margin-top: 10px;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          color: $darkGunmetal;
          &:before {
            display: inline-block;
            content: "";
            width: 18px;
            height: 12px;
            background-image: url(../../src/assets/images/arrow-left.png);
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 5px;
            vertical-align: top;
            margin-top: 4px;
            @include transition(0.3s);
          }
          &:hover {
            &:before {
              margin-right: 15px;
            }
          }
        }
        .btn {
          padding-left: 25px;
          padding-right: 25px;
          font-weight: 500;
          margin-top: 20px;
          margin-bottom: 20px;
          @include md {
            margin: 0;
          }
          &:hover {
            .fa {
              margin-right: -10px;
            }
          }
          .fa {
            margin-top: 4px;
            font-weight: 300;
            float: right;
            width: 20px;
            height: 13px;
            background-image: url(../../src/assets/images/arrow-right.png);
            background-position: center center;
            background-repeat: no-repeat;
            @include transition(0.3s);
            &.lock {
              width: 14px;
              height: 18px;
              background-image: url(../../src/assets/images/lock-icn.png);
              margin-top: 0;
            }
          }
        }
        label {
          position: relative;
          padding-left: 30px;
          > input[type="checkbox"] {
            position: absolute;
            top: 0;
            left: 0;
          }
          span {
            a {
              color: $blue;
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
        p {
          font-weight: 400;
          color: $graniteGrey;
          a {
            font-weight: 600;
            color: $graniteGrey;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    .buy-div {
      max-width: 700px;
      .btn {
        margin-top: 30px;
      }
    }
  }
}

/* Option Side */
.Optionside {
  h6 {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 20px;
    }
    a {
      color: $blue;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .box {
    @include md {
      margin-bottom: 20px;
    }
    .bg {
      background-color: $white;
      height: 100%;
      @include box-shadow(0 5px 10px rgba(0, 0, 0, 0.1));
      .title {
        padding: 10px 15px;
        background-color: $orange;
        p {
          font-weight: $fontFamilyPop;
          font-weight: 700;
          color: $white;
          margin-bottom: 0;
          span {
            font-size: 22px;
          }
        }
      }
      .text {
        padding: 15px;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            padding-left: 30px;
            margin-bottom: 20px;
            background-image: url(../../src/assets/images/check-icn.png);
            background-position: 0 5px;
            background-repeat: no-repeat;
            background-size: 16px auto;
            &:last-child {
              margin-bottom: 0;
            }
            a {
              color: $blue;
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}

/* Duke Side */
.Dukeside {
  padding: 20px 0;
  border-top: 1px solid $philippineSilver;
  border-bottom: 1px solid $philippineSilver;
  background-color: $white;
  h2 {
    font-family: $fontFamilyPop;
    display: flex;
    align-items: center;
    img {
      max-width: 50px;
      margin-right: 10px;
      @include lg {
        max-width: 50px;
      }
    }
  }
  .level {
    font-size: 20px;
    font-family: $fontFamilyPop;
    font-weight: 600;
    color: $darkGrey;
    padding-left: 40px;
    @include lg {
      font-size: 16px;
      padding-left: 30px;
    }
  }
  h3 {
    font-family: $fontFamilyPop;
    margin-bottom: 0;
    span {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

/* Score Side */
@mixin gameCard {
  margin: auto;
  @media (max-width: 1040px) {
    width: 300px;
    padding: 10px;
  }
  width: 350px;
  border: 1px solid $lightGrey;
  border-radius: 24px;
  padding: 1rem;
  position: relative;
  min-height: 13rem;
  height: 100%;

  .gameCardHeader {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
    a:hover {
      cursor: pointer;
      color: #ef6c00;
      text-decoration: underline;
      h3 {
        color: #ef6c00;
      }
    }

    .scores-team-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      h3 {
        margin-bottom: 0;
        text-align: center;
        font-size: 1rem;
      }
      max-width: 8rem;
      text-align: center;
    }

    .gameDate {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: center;
      padding: 0%;
      height: 100%;
      h3 {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          color: #ef6c00;
          display: flex;
          align-items: center;
          svg {
            margin-left: 2px;
          }
        }
      }

      h4 {
        margin-bottom: 0;
        text-align: center;
        flex: 1;
        font-size: 0.8rem;
      }

      h5 {
        text-align: center;
        margin-bottom: 0;
        flex: 1;
      }

      p {
        width: 100%;
        text-align: center;
        margin-top: auto;
      }
    }
  }
  .gameCardContent {
    width: 100%;
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      thead {
        tr {
          th {
            text-align: center;
            padding: 10px;
            border-bottom: 1px solid $lightGrey;
          }
        }
      }
      tbody {
        tr {
          .TitleDiv {
            span {
              line-height: 12px;
            }
            .quality {
              color: #ef6c00;
            }
          }
          td {
            text-align: center;
            padding: 10px;
            border-bottom: 1px solid $lightGrey;

            div {
              line-height: 1rem;
            }

            span {
              font-family: var(--font-inter);
              font-weight: 700;
              font-size: 12px;
              letter-spacing: normal;
              text-transform: capitalize;
              width: 100%;
            }

            .AppIcon {
              display: inline-block;
              background: url(https://embed-d.sq.video/assets/ball-f74d2477.png) no-repeat center center;
              background-size: 12px;
              min-width: 12px;
              min-height: 10px;
              line-height: 12px;
            }
          }
          .info {
            span {
              font-size: 1rem;
            }
          }
        }
        .score {
          .away {
            font-weight: bold;
            font-size: 1rem;
          }
          .home {
            font-weight: bold;
            font-size: 1rem;
          }
        }
        .pps {
          .away {
            font-weight: bold;
            font-size: 1rem;
            .points {
              font-weight: bold;
              font-size: 1rem;
            }
            .difference {
              font-weight: 100;
              font-size: 0.7rem;
            }
          }
          .home {
            font-weight: bold;
            font-size: 1rem;
            .points {
              font-weight: bold;
              font-size: 1rem;
            }
            .difference {
              font-weight: 100;
              font-size: 0.7rem;
            }
          }
        }
        .pps.actual {
          .away {
            display: flex;
            flex-direction: column;
            .points span {
              font-size: 1rem !important;
            }
          }
          .home {
            display: flex;
            flex-direction: column;
            .points span {
              font-size: 1rem !important;
            }
          }
        }
      }
    }

    .OutlierWrapper {
      margin-top: 0.5rem;
      height: 2.5rem;
      overflow: hidden;
      position: relative;
      width: 100%;
    }
    .Outlier {
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
      opacity: 1;

      .fade-out {
        transform: translateY(-100%);
        opacity: 0;
      }
    }
  }
  .gameCardFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    a {
      margin-top: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-weight: bold;
      color: #ef6c00;
    }
  }
  .GameCardPrediction {
    font-size: 0.8rem;
  }

  .form-check.check-box {
    display: flex;
    justify-content: right;
    width: 100%;
    padding-left: 0px;
    gap: 1rem;
    padding: 0.5rem;
    color: white;
  }
  .date-holder {
    position: absolute;
    top: 1.1rem;
    left: 0;
    width: 100%;
    font-weight: 600;
  }
}

@mixin gameProgress {
  @media (max-width: 1000px) {
    height: 20px;
  }
  height: 30px;
  width: 100%;
  background-color: $lightGrey;
  border-radius: 24px;
  position: relative;
}
@mixin fillProgress {
  position: absolute;
  @media (max-width: 1000px) {
    height: 20px;
    width: 152px;
  }
  height: 30px;
  width: 152px;
  background-color: $crayola;
  border-radius: 24px;
}
.score-center-card-holder {
  .gameCard {
    @include gameCard;
  }
  .gameProgress {
    @include gameProgress;
  }
  .fillProgress {
    @include fillProgress;
  }
}
.Scoreside {
  // padding-top: 80px;
  padding-bottom: 30px;
  @include md {
    //        padding-bottom: 10px;
  }
  .header-primary-box {
    justify-content: space-evenly;
    .search-box {
      display: flex;
      align-items: flex-end;
      .form-input {
        width: 100%;
      }
    }
  }

  .scoreHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    // width: 100%;
    .headerTitle {
      font-weight: 700;
      font-size: 44px;
      line-height: 150%;
      color: $black;

      @media (max-width: 820px) {
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }
    .headerBtnsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      // width: 35em;
      .headerBtns {
        width: 300px;
        padding: 16px 40px;
        border-radius: 12px;
      }
      .latestGameBtn {
        width: 300px;
        padding: 16px 40px;
        border-radius: 12px;
        background-color: $chineseBlack;
        color: $white;
        margin-right: 30px;
        @media (max-width: 820px) {
          margin-right: 0;
        }
      }
      @media (max-width: 820px) {
        flex-direction: column;
        align-items: center;
      }
    }
    @media (max-width: 820px) {
      flex-direction: column;
    }
  }
  .latestGameBtn {
    // width: 300px;
    padding: 16px 25px;
    border-radius: 12px;
    background-color: $chineseBlack;
    color: $white;
    margin-right: 30px;
    text-align: center;
    @media (max-width: 820px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  @media (min-width: 1401px) {
    padding-right: 3.5vw;
    padding-left: 3.5vw;
    margin: auto;
    max-width: 1600px;
  }
  @media (max-width: 820px) {
    padding-right: 3.5vw;
    padding-left: 3.5vw;
    margin: auto;
    max-width: 1600px;
  }
  .value-finder-buttons-group {
    color: $white;
    background-color: transparent !important;
    border-radius: 10px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    opacity: 1;

    &.active {
      background: $orange !important;
    }
  }
  .switch-container {
    text-align: left !important;
  }
  .dropDown {
    @media (max-width: 820px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .btnWrapp {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 50;
    @media (max-width: 820px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .sportlogo {
    margin-bottom: 15px;
    a {
      font-size: 12px;
      font-family: $fontFamilyPop;
    }
  }
  .based {
    padding-bottom: 20px;
    p {
      padding: 15px;
      border: 1px solid $spanishGrey;
      font-size: 22px;
      font-family: $fontFamilyPop;
      font-weight: 500;
      color: $black;
      margin-bottom: 0;
      display: inline-block;
      line-height: 1.7em;
      @include lg {
        font-size: 18px;
      }
      span {
        font-size: 32px;
        color: $orange;
        @include lg {
          font-size: 24px;
        }
      }
    }
  }
  .finalscroe {
    margin-bottom: 15px;
    h2 {
      font-family: $fontFamilyPop;
      text-transform: uppercase;
    }
  }
  .dukescroe {
    margin-bottom: 20px;
    &.extra-space {
      position: relative;
      margin-top: -40px;

      @media (max-width: 991px) {
        margin-top: 0;
      }
    }

    .graphCover {
      width: 100%;
      padding-bottom: 50%;
      position: relative;
      height: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .table {
      margin: 0;
    }
    h2 {
      font-family: $fontFamilyPop;
      text-transform: uppercase;
    }
    .box {
      @include md {
        margin-bottom: 15px;
      }
      &.bdr {
        padding: 15px;
        border: 1px solid $spanishGrey;
        height: 100%;
        background-color: $cultured;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        a {
          display: block;
          &:hover {
            .zoom {
              opacity: 1;
            }
          }
          .zoom {
            @include transition(0.3s);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 44px;
            opacity: 0;
          }
        }
      }

      .graphCover {
        width: 100%;
        padding-bottom: 50%;
        position: relative;
        height: 100%;
      }
      .bdr {
        padding: 15px;
        border: 1px solid $spanishGrey;
        height: 100%;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h5 {
        font-family: $fontFamilyPop;
      }
    }

    h4 {
      font-family: $fontFamilyPop;
      text-transform: uppercase;
      span {
        font-weight: 400;
      }
    }
  }

  tbody {
    h5 {
      padding: 0.5rem;
      background-color: $orange;
      color: $white;
    }
  }

  .container-fluid {
    .dukescroe {
      @media (min-width: 1200px) and (max-width: 1400px) {
        width: auto;
      }
    }
  }

  .title {
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;
    margin-top: 1rem;
    .bg {
      position: relative;
      padding: 7px 45px 7px 25px;
      font-size: 20px;
      font-weight: 700;
      display: inline-block;
      background-color: $orange;
      color: $white;
      overflow: hidden;
      &:after {
        position: absolute;
        top: 0;
        right: -35px;
        width: 50px;
        height: 100%;
        @include border-radius(50%);
        content: "";
        background-color: $bgcolor;
      }
    }
  }

  .container-fluid {
    .dukescroe {
      @media (min-width: 1200px) and (max-width: 1400px) {
        width: auto;
      }
    }
  }

  .gameCardList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    row-gap: 1rem;

    @media (max-width: 1040px) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .gameCard {
      @include gameCard;
    }
    .gameProgress {
      @include gameProgress;
    }
    .fillProgress {
      @include fillProgress;
    }
  }
}

#ScoreCenter {
  padding-right: 0.5vw;
  padding-left: 0.5vw;
  max-width: 100vw;
  .row {
    margin: 1rem;
    align-items: center;
    .form-group {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 1401px) {
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    max-width: 100vw;
  }
}
/* Title Side */
.Titleside {
  padding-top: 80px;
  padding-bottom: 40px;

  .teamHeader {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $white;
    position: sticky;
    top: 56px;
    z-index: 9;
    @include sm {
      position: relative;
      top: auto;
      z-index: auto;
      img {
        height: 4rem;
        width: auto;
      }
    }
  }
  @include xl {
    padding-top: 0;
  }

  h2 {
    font-size: 36px;
    color: $chineseBlack;
    margin-bottom: 0;
    @include md {
      margin-bottom: 15px;
    }

    @media (max-width: #{$md}) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  .search {
    display: inline-block;
    .form-control {
      padding-right: 50px;
      // max-width: 250px;
    }

    .form-group {
      margin-bottom: 0;
    }
    .btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0 15px 0 0;
      img {
        width: 18px;
      }
    }
  }
  &.team {
    img {
      margin-right: 20px;
      max-width: 105px;
      @include md {
        margin: 0 0 15px;
      }
    }
    h2 {
      font-family: $fontFamilyPop;
      margin-bottom: 0;
      span {
        font-weight: 400;
      }
    }
    p {
      // color: $white;
      font-weight: 700;
      margin-bottom: 0;
      color: $chineseBlack;
      .name {
        font-weight: 400;
      }
    }
  }

  .container-fluid {
    .dukescroe {
      @media (min-width: 1200px) and (max-width: 1400px) {
        width: auto;
      }

      @include container-fluid-lg;
    }
  }
}

/* History Side */
.Historyside {
  padding-top: 50px;
  padding-bottom: 50px;
  @include lg {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      padding: 0 10px;
      display: inline-block;
      &:first-child {
        &:after {
          content: "";
          width: 4px;
          height: 4px;
          background-color: $darkBlue;
          display: inline-block;
          @include border-radius(50%);
          vertical-align: top;
          margin-top: 12px;
          margin-left: 10px;
        }
      }
      &:last-child {
        &:before {
          content: "";
          width: 4px;
          height: 4px;
          background-color: $darkBlue;
          display: inline-block;
          @include border-radius(50%);
          vertical-align: top;
          margin-top: 12px;
          margin-right: 10px;
        }
      }
      a {
        font-size: 20px;
        font-family: $fontFamilyPop;
        font-weight: 400;
        color: $darkBlue;
        @include lg {
          font-size: 16px;
        }
        &:hover {
          color: $orange;
        }
        &.active {
          color: $gray;
        }
      }
    }
  }
}

/* Stat Side */
.Statside {
  min-height: 350px;
  .container {
    background: $white;
    padding: 30px;
    box-shadow: 0px 0px 5px silver;
  }
  .text-center {
    p {
      margin-bottom: 30px;
    }
  }
  h2 {
    font-family: $fontFamilyPop;
    color: $orange;
  }
  p {
    b {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .row {
    padding-top: 30px;
  }
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      position: relative;
      padding-left: 15px;
      font-size: 16px;
      font-family: $fontFamilyPop;
      font-weight: 400;
      color: $codGrey;
      margin-bottom: 10px;
      &:before {
        position: absolute;
        top: 8px;
        left: 0;
        content: "";
        width: 6px;
        height: 6px;
        @include border-radius(50%);
        background-color: $orange;
      }
      &:last-child {
        margin-bottom: 0;
      }
      ol {
        display: block;
        margin-top: 10px;
        list-style: none;
        padding: 0;
        li {
          color: $darkLiver;
        }
      }
    }
  }
  .title {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    .bg {
      position: relative;
      padding: 7px 45px 7px 25px;
      font-size: 20px;
      font-weight: 700;
      display: inline-block;
      background-color: $orange;
      color: $white;
      overflow: hidden;
      &:after {
        position: absolute;
        top: -3px;
        right: -35px;
        width: 50px;
        height: 50px;
        @include border-radius(50%);
        content: "";
        background-color: $white;
      }
    }
    .darkbg {
      position: relative;
      padding: 7px 45px 7px 25px;
      font-size: 20px;
      font-weight: 700;
      display: inline-block;
      background-color: $orange;
      color: $white;
      overflow: hidden;
      &:after {
        position: absolute;
        top: -3px;
        right: -35px;
        width: 50px;
        height: 50px;
        @include border-radius(50%);
        content: "";
        background-color: $darkbgcolor;
      }
    }
  }
  h4 {
    font-family: $fontFamilyPop;
    font-weight: 700;
  }
  h5 {
    font-family: $fontFamilyPop;
    font-weight: 500;
    span {
      color: $orange;
    }
  }
  .question {
    margin-top: 50px;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    @include md {
      padding-bottom: 15px;
    }
    .btn {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 700;
      @include sm {
        font-size: 16px;
      }
    }
    a {
      font-size: 18px;
      font-family: $fontFamilyPop;
      font-weight: 700;
      color: $white;
      text-transform: none;
      @include sm {
        font-size: 16px;
      }
    }
    .form-group {
      margin-bottom: 0;
      @include md {
        margin-bottom: 15px;
      }
    }
  }
  .table {
    margin-bottom: 0;
    @include sm {
      margin-bottom: 15px;
    }
  }
  .box {
    .bdr {
      height: 100%;
      width: 100%;
      border: 1px solid $philippineSilver;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gallery;
      position: relative;
      a {
        display: block;
        &:hover {
          .zoom {
            opacity: 1;
          }
        }
        .zoom {
          @include transition(0.3s);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 44px;
          opacity: 0;
        }
      }
    }
  }
}

/* Filter Side */
.Filterside {
  padding-top: 30px;
  padding-bottom: 30px;

  @include md {
    padding-bottom: 0;
  }
  .box {
    @include md {
      margin-bottom: 15px;
    }
    h4 {
      font-weight: 600;
      font-family: $fontFamilyPop;
      margin-bottom: 25px;
      @include md {
        margin-bottom: 10px;
      }
    }
    ul {
      list-style: none;
      margin-bottom: 0;
      padding: 0;
      li {
        font-size: 18px;
        font-family: $fontFamilyPop;
        font-weight: 500;
      }
    }
    .form-group {
      margin-bottom: 0;
      @include md {
        margin-bottom: 15px;
      }
      .form-control {
        font-size: 20px;
        font-family: $fontFamilyPop;
        @include border-radius(0);
        background-color: transparent;
        text-align: center;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .custom-select {
        @include border-radius(0);
        height: 42px;
        font-size: 24px;
        font-weight: 400;
        font-family: $fontFamilyPop;
        text-align: center;
        background: $white url("../../src/assets/images/select-arrow1.png") right center no-repeat;
      }
    }
  }

  @media (max-width: #{$md}) {
    padding-top: 10px;
    padding-bottom: 10px;

    .box {
      margin-bottom: 0px;
    }
  }

  .container-fluid {
    .dukescroe {
      @media (min-width: 1200px) and (max-width: 1400px) {
        width: auto;
      }

      @include container-fluid-lg;
    }
  }
}
/* Time Side */
.Timeside {
  padding-bottom: 30px;
  .status {
    font-size: 22px;
    font-family: $fontFamilyPop;
    font-weight: 400;
    display: block;
    margin-bottom: 30px;
    @include md {
      font-size: 18px;
    }
  }
  .dateCalendar {
    @include md {
      padding-left: 50px;
      padding-right: 50px;
    }
    .item {
      padding: 0 10px;
      &:hover,
      &.active {
        color: $orange;
      }
      color: $eerieBlack;
      cursor: pointer;
      span {
        font-family: $fontFamilyPop;
        display: block;
        text-transform: uppercase;
        &.day {
          font-weight: 500;
        }
        &.date {
          font-weight: 400;
        }
      }
    }
    .slick-arrow {
      width: 56px;
      height: 56px;
      border: 1px solid $spanishGrey;
      @include border-radius(50%);
      background-color: $bgcolor;
      @include md {
        width: 40px;
        height: 40px;
      }
      &:before {
        font-size: 34px;
        @include md {
          font-size: 24px;
        }
      }
      &.slick-prev {
        left: -65px;
        @include md {
          left: 0px;
        }
      }
      &.slick-next {
        right: -65px;
        @include md {
          right: 0px;
        }
      }
    }
  }
  .calendarOpen {
    position: absolute;
    bottom: 0;
    right: -120px;
    width: 44px;
    @include md {
      position: relative;
      right: auto;
      margin: 20px 0 0;
      width: 100%;
    }
    label {
      display: block;
      margin: 0;
      input {
        width: 0;
        height: 0;
        border: 0;
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}
/* Year side */
.Yearside {
  padding-bottom: 20px;
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      padding: 0 10px;
      display: inline-block;
      a {
        font-size: 24px;
        font-family: $fontFamilyPop;
        font-weight: 500;
        color: $gray;
        @include lg {
          font-size: 16px;
        }
        &:hover {
          color: $orange;
        }
        &.active {
          color: $orange;
          font-weight: 600;
        }
      }

      h3 {
        font-size: 22px;
        font-weight: bold;
        color: $gray;
      }
    }
  }
}

/* Rank side */
.Rankside {
  padding-top: 20px;
  padding-bottom: 3s0px;
  @include md {
    padding-bottom: 20px;
  }
  h2 {
    color: $orange;
    margin-bottom: 30px;
  }
  .box {
    margin-bottom: 30px;
    @include md {
    }
    .bdr {
      padding: 20px;
      width: 100%;
      height: 100%;
      border: 1px solid $silverChalice;
      background-color: $white;
      span {
        font-size: 16px;
        font-family: $fontFamilyPop;
        font-weight: 500;
        color: $raisinBlack;
        margin-bottom: 15px;
      }
    }
  }
}

/* Tenden Side */
.Tendenside {
  padding-bottom: 50px;
  @include md {
    padding-bottom: 10px;
  }
  .box {
    @include md {
      padding-bottom: 40px;
    }
    h3 {
      margin-bottom: 30px;
    }
    .bdr {
      padding: 20px;
      border: 1px solid $philippineSilver;
      width: 100%;
      background-color: $white;
    }
  }
}
.calendar-text-wrapper {
  position: relative;
  img + .text {
    position: absolute;
    top: 41px;
    left: 18px;
    text-align: center;
    max-width: 116px;
    display: flex;
    font-size: 1.5rem;
    font-weight: 800;
    color: black;
    height: 66px;
    align-items: center;
    line-height: 1;
    justify-content: center;
    width: 100%;
  }
}
.testimonials-new {
  background: #1a202a;
  padding: 3rem 0rem;
  color: white;
  h2 {
    max-width: 50rem;
    margin: auto;
    text-align: center;
    padding-bottom: 2rem;
    color: white;
  }
  .review {
    background: #262f3f;
    height: 250px;
    min-width: 320px;
    border-radius: 10px;
    margin: 1rem;
    padding: 24px;
    margin-bottom: 4rem;
    position: relative;
    * {
      color: $white;
    }
    @include xl {
      min-width: 370px;
      margin-right: 40px;
    }
    @include lg {
      min-width: 360px;
      margin-right: 20px;
    }
    @include sm {
      min-width: 300px;
    }
    img {
      margin-right: 16px;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
    }
    #reviewer {
      border-radius: 50%;
    }
    h3 {
      font-size: 20px;
      font-weight: 700;
      max-width: 10rem;
      text-align: left;
    }
    .quote-svg {
      position: absolute;
      top: -16px;
      left: 24px;
    }
  }
  .react-multi-carousel-dot--active button {
    background: #f5a623;
  }
}
.plan-block .update-plan .card {
  width: 12rem;
}
.Toastify__close-button {
  pointer-events: all;
}
// .Toastify__toast-container .Toastify__toast {
//   border: 4px solid red;
// }
.Toastify__progress-bar--error {
  background: red !important;
}
